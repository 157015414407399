.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  /* background-image: url(https://images.unsplash.com/photo-1601652589234-c282487ec97e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2l1ZGFkJTIwZGUlMjBtZXhpY298ZW58MHx8MHx8fDA%3D&w=1000&q=80); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.row {
  display: flex;
  flex: 1;
}

.column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1 {
  color: #111;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  margin: 0 0 0;
  padding: 20px 30px;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  color: #111;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

h4 {
  color: #111;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

p {
  color: #111;
  font-family: "Open Sans", sans-serif;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 16px;
  margin-top: 0%;
}
